import React from 'react';

const Lore = () => {
    return (
        <>
            <p>
                Every satoshi has a name. Every satoshi has a story.
            </p>
            <p>
                Satoshi Cards are a collection of digital trading cards that bring these names to life.
            </p>
            <p>
                Each card is inscribed on and draws its theme from a uniquely named satoshi.
            </p>
            <p>
                To learn more about satoshi names, check out the <a target="_blank" href={"https://docs.ordinals.com/overview.html#names"}>ordinals handbook. </a>
            </p>
            <p>
                The tentative plan is to release about 50 cards per week.
            </p>
            <p>
                Total supply will naturally be constrained by the rarity of uniquely named satoshis which are very difficult to find.
            </p>
        </>
    )
}

export default Lore