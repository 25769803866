import React, { useState, useRef, useEffect } from 'react';
import './App.css';

import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import InputGroup from 'react-bootstrap/InputGroup';
import { FaRegCopy } from 'react-icons/fa';
import Form from 'react-bootstrap/Form';
import Card from 'react-bootstrap/Card';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Modal from 'react-bootstrap/Modal';
import { TailSpin } from 'react-loading-icons'
import { QRCodeSVG } from 'qrcode.react';
import Countdown from 'react-countdown';
import useInterval from 'react-useinterval';
import TitleImage from './assets/images/sat-cards.gif'
import config from '../config.json'
import Lore from './Lore'
import { validate, Network } from 'bitcoin-address-validation';
import { getAddress, signTransaction } from 'sats-connect';
import * as bitcoin from 'bitcoinjs-lib'
import * as ecc from 'tiny-secp256k1'
bitcoin.initEccLib(ecc)
const axios = require('axios')

const TESTNET = false
const MIN_FEE_RATE = '10'
const RECOMMENDED_FEE_RATE = '27'
const COLLECTION_ID = TESTNET ? 'd09f60c3eecfd368bc51ac586c5a394a' : config.collectionId
const API_BASE_URL = `https://api${TESTNET ? '-testnet' : ''}.deezy.io`
const INVOICE_EXPIRY_MS = 300000
const MEMPOOL_URL = `https://mempool.space${TESTNET ? '/testnet' : ''}`
const UTXO_BASE_SATS = 10000
const MARKETPLACE_URL = 'https://magiceden.io/ordinals/marketplace/satoshi-cards'
function importAll(r) {
  return r.keys().map(r);
}
const imageSrcModules = importAll(require.context('./assets/images/samples', false, /\.(png|jpe?g|svg)$/));

import { SocialIcon } from 'react-social-icons';
import { FadeIn } from 'react-slide-fade-in';
import NavLink from 'react-bootstrap/esm/NavLink';

const countdownRenderer = ({ hours, minutes, seconds, completed }) => {
  if (completed) {
    // Render a completed state
    return <span>the invoice has expired</span>;
  } else {
    // Render a countdown
    return <span>expires in: {hours > 0 ? `${hours}h` : ''} {minutes > 0 ? `${minutes}m` : ''} {seconds}s</span>;
  }
}


const App = () => {
  const exploreRef = useRef(null);
  const scrollToRef = (ref) => ref.current.scrollIntoView({ block: 'start', behavior: 'smooth' });
  const [showBeginMintModal, setShowBeginMintModal] = useState(false);
  const [showSelectFeeRateModal, setShowSelectFeeRateModal] = useState(false);
  const [showPromptForAddressModal, setShowPromptForAddressModal] = useState(false);
  const [showNotAllowedModal, setShowNotAllowedModal] = useState(false);
  const [mintFeeRate, setMintFeeRate] = useState(RECOMMENDED_FEE_RATE);
  const [numToMint, setNumToMint] = useState(1);
  const [showCustomAddressModal, setShowCustomAddressModal] = useState(false);
  const [isBtcInputAddressValid, setIsBtcInputAddressValid] = useState(true);
  const [destinationBtcAddress, setDestinationBtcAddress] = useState('');
  const [paymentBtcAddress, setPaymentBtcAddress] = useState('');
  const [showConfirmMintModal, setShowConfirmMintModal] = useState(false);
  const [customAddressUsed, setCustomAddressUsed] = useState(false)
  const [nostrPublicKey, setNostrPublicKey] = useState('')
  const [showAwaitingInvoiceModal, setShowAwaitingInvoiceModal] = useState(false)
  const [showErrorModal, setShowErrorModal] = useState(false)
  const [invoiceToPay, setInvoiceToPay] = useState("")
  const [invoiceDetails, setInvoiceDetails] = useState({})
  const [mintAttemptId, setMintAttemptId] = useState("")
  const [showPayLightningModal, setShowPayLightningModal] = useState(false)
  const [showPayOnchainModal, setShowPayOnchainModal] = useState(false)
  const [showChoosePaymentModal, setShowChoosePaymentModal] = useState(false)
  const [showQrCode, setShowQrCode] = useState(false)
  const [showAwaitingMintModal, setShowAwaitingMintModal] = useState(false)
  const [showFinalInfoModal, setShowFinalInfoModal] = useState(false)
  const [mintOutpoints, setMintOutpoints] = useState([])
  const [minted, setMinted] = useState(false)
  const [collectionInfo, setCollectionInfo] = useState({})
  const [showNoneAvailableModal, setShowNoneAvailableModal] = useState(false)
  const [showViewInfoModal, setShowViewInfoModal] = useState(false)
  const [maxMint, setMaxMint] = useState(1)
  const [showLoadingModal, setShowLoadingModal] = useState(false)
  const [showPendingOnchainModal, setShowPendingOnchainModal] = useState(false)

  function shortenStr(str) {
    return str.substring(0, 8) + "..." + str.substring(str.length - 8, str.length)
  }

  function liveCollectionInfo() {
    console.log(collectionInfo)
    return (<>
      <b>View Listings</b> on <a href={MARKETPLACE_URL} target="_blank">Magic Eden</a>
      <br /><br />
      <b>Available:</b> {collectionInfo.num_available}
      <br /><br />
      <b>Minted:</b> {collectionInfo.num_minted}
      <br /><br />
      <b>Price:</b> {collectionInfo.base_price_sats / 100000000} BTC + Minting Fee
      <br /><br />
    </>)
  }

  const fetchPaymentStatusLoop = async () => {
    if ((!showPayLightningModal && !showPayOnchainModal && !showChoosePaymentModal && !showFinalInfoModal && !showPendingOnchainModal) || !mintAttemptId) return
    //console.log(`polling for invoice status`)
    let response = null
    try {
      response = await axios.get(`${API_BASE_URL}/v1/inscriptions/mint?mint_id=${mintAttemptId}`)
    } catch (err) {
      console.error(err)
    }
    if (response && response.data) {
      console.log(response.data)
      const status = response.data.status
      if (status === 'FAILED') {
        setShowPendingOnchainModal(false)
        setShowErrorModal(true)
        setPaymentBtcAddress('')
        setInvoiceToPay('')
        setMintAttemptId('')
        return
      }
      if (status === 'PAID' || status === 'PAID_ON_CHAIN') {
        setShowPayLightningModal(false)
        setShowPayOnchainModal(false)
        setShowPendingOnchainModal(false)
        setShowFinalInfoModal(true)
        return
      } else if (status === 'MINTED') {
        setShowPayLightningModal(false)
        setShowPayOnchainModal(false)
        setShowPendingOnchainModal(false)
        setMintOutpoints(response.data.mint_outpoints)
        setMinted(true)
        setShowFinalInfoModal(true)
        return
      }
    }

    if (!paymentBtcAddress) return
    response = null
    try {
      response = await axios.get(`${MEMPOOL_URL}/api/address/${paymentBtcAddress}`)
    } catch (err) {
      console.error(err)
      return
    }

    if (response && response.data) {
      const { chain_stats, mempool_stats } = response.data
      const sumFundedSats = chain_stats.funded_txo_sum + mempool_stats.funded_txo_sum
      if (sumFundedSats >= totalPriceSats()) {
        setShowPayLightningModal(false)
        setShowPayOnchainModal(false)
        setShowPendingOnchainModal(true)
      }
    }
  }
  useInterval(fetchPaymentStatusLoop, 2000)

  const fetchCollectionInfoLoop = async () => {
    let response = null
    try {
      response = await axios.get(`${API_BASE_URL}/v1/inscriptions/collections/info?collection_id=${COLLECTION_ID}`)
    } catch (err) {
      console.error(err)
    }
    if (response && response.data) {
      setCollectionInfo(response.data)
      // console.log(response.data)
    }
  }
  useInterval(fetchCollectionInfoLoop, 10000)
  useEffect(fetchCollectionInfoLoop, [])

  async function fetchAndHandleAllowlistDetails(address) {
    if (!collectionInfo.allowlist_enabled) {
      setMaxMint(collectionInfo.max_per_mint)
      setShowBeginMintModal(true)
      return
    }
    setShowLoadingModal(true)

    const resp = await axios.get(`${API_BASE_URL}/v1/inscriptions/collections/allowlist?collection_id=${COLLECTION_ID}&address=${address}`).catch(err => {
        console.error(err)
        setShowLoadingModal(false)
        setShowErrorModal(true)
        return null
    })
    if (!resp) return

    console.log(resp.data)
    const maxMint = Math.min(resp.data.num_allowed - resp.data.num_used, collectionInfo.max_per_mint)
    setShowLoadingModal(false)
    if (maxMint <= 0) {
      setShowNotAllowedModal(true)
    } else {
      setMaxMint(maxMint)
      setShowBeginMintModal(true)
    }
  }
  async function handleBtcAddressChange(evt) {
    const newaddr = evt.target.value
    if (newaddr === '') {
      setIsBtcInputAddressValid(true)
      return
    }
    if (!validate(newaddr, TESTNET ? Network.testnet : Network.mainnet)) {
      setIsBtcInputAddressValid(false)
      return
    }
    setDestinationBtcAddress(newaddr)
    setShowCustomAddressModal(false)
    // setShowConfirmMintModal(true)
    setCustomAddressUsed(true)
    //setShowLoadingModal(true)
    await fetchAndHandleAllowlistDetails(newaddr)
  }

  function miningFeeSats() {
    return ((collectionInfo.fee_rate_multiplier || 0) * mintFeeRate * numToMint)
  }

  function totalPriceSats() {
    return Math.round(((collectionInfo.base_price_sats + UTXO_BASE_SATS) * numToMint) + miningFeeSats())
  }

  function totalPriceBtcDisplay() {
    return `${(totalPriceSats() / 100000000)} BTC`
  }

  return (
    <>
      <Navbar id="top-nav" className="pt-3 pb-3 text-center" expand="lg">
        <Container>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto">
              <Nav.Link className="black" onClick={() => scrollToRef(exploreRef)}>
                About
              </Nav.Link>
              {/**
            <NavLink className="black">
              FAQ
            </NavLink>
             */
              }{ /**
            <NavLink className="black">
              How to Mint
            </NavLink>
             */
              }
              <NavLink className="black" onClick={() => window.open(MARKETPLACE_URL)}>
                Marketplace
              </NavLink>
            </Nav>
            <Nav id="top-social-links">
              {config.socialLinks.map((socialLink) => {
                return (
                  <SocialIcon url={socialLink} target="_blank" className="social-icon nav-link" />
                )
              })
              }
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <Container bg="light" variant="light" className="main-container d-flex text-center align-items-center justify-content-center">
        <Container className="top-panel">
          <h1 id="title">{config.title}</h1>
          <div>{config.subtitle}</div>
          <Container className="mt-4 d-flex flex-row align-items-center justify-content-center">
            {
              collectionInfo.name ?
                <>
                  <Button variant="outline-primary" className="mx-2 button" onClick={() => setShowViewInfoModal(true)}>View Info</Button>
                  <Button variant="primary" className="mx-2 button shadowed-color-small" onClick={
                    () => {
                      if (collectionInfo.num_available === 0) {
                        window.open(MARKETPLACE_URL)
                        return
                      }
                      // setShowBeginMintModal(true)
                      setShowPromptForAddressModal(true)
                      setMintOutpoints([])
                      setMinted(false)
                    }
                  }>{
                      collectionInfo.num_available > 0 ?
                        <>Mint ⚡ Now</>
                        :
                        <>Mint ⚡ Soon</>
                    }
                  </Button>
                </>
                :
                <>
                  <TailSpin stroke="#000000" speed={.75} />
                </>
            }
          </Container>
        </Container>
        <Container id="top-img" className="top-panel d-flex flex-wrap justify-content-center">
          <img style={{ width: '80%', maxWidth: '250px', borderRadius: '20px' }} alt="" src={TitleImage} className="shadowed-color" />
        </Container>
      </Container>

      <Container ref={exploreRef} id="lore-section" className="section mt-5 text-center">
        <Lore />
      </Container>
      <Container className="section d-flex flex-column text-center align-items-center justify-content-center">
        {
          config.bottomTagline ?
            <div><i>{config.bottomTagline}</i></div>
            : <></>
        }
        <Container className="d-flex flex-row text-center align-items-center my-1" style={{
          maxWidth: '800px',
          justifyContent: `center`
        }}>
          {config.socialLinks.map((socialLink) => {
            return (
              <SocialIcon url={socialLink} target="_blank" className="social-icon" />
            )
          })
          }
        </Container>
        <p className="text-center">
          Powered by <a href="https://deezy.io" target="_blank">Deezy</a> ⚡ ❤️
        </p>
        <br />
        <p className="small-text">
          <a href="https://assets.astralbabes.ai/privacy-policy.pdf" target="_blank">Privacy Policy</a> | <a href="https://assets.astralbabes.ai/terms-of-service.pdf" target="_blank">Terms of Service</a>
        </p>
      </Container>
      <Modal show={showBeginMintModal} onHide={() => setShowBeginMintModal(false)} className="py-5">
        <Modal.Header closeButton className="p-4">
          <Modal.Title>Mint {config.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="modal-body p-4">
          <div>
            You can mint up to {maxMint} {config.name}{maxMint === 1 ? '' : 's'}!
          </div>
          <div className="my-3">
            <Form.Control type="number" className="w-25 text-center" step={1} max={Math.min(collectionInfo.max_per_mint, collectionInfo.num_available, maxMint)} min={1} onChange={(evt) => setNumToMint(evt.target.value)} value={numToMint} />
          </div>
          <div>
            <b>Price:</b> {(numToMint * collectionInfo.base_price_sats) / 100000000} BTC + Minting Fee
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowBeginMintModal(false)}>
            Cancel
          </Button>
          <Button variant="primary" onClick={() => {
            setShowBeginMintModal(false);
            setShowSelectFeeRateModal(true);
          }}>
            Next
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={showSelectFeeRateModal} onHide={() => setShowSelectFeeRateModal(false)} className="py-5">
        <Modal.Header closeButton className="p-4">
          <Modal.Title>Choose Confirmation Speed</Modal.Title>
        </Modal.Header>
        <Modal.Body className="modal-body p-4">
          <p>
            Select a fee rate for your mint
          </p>
          <p>
            <b>{mintFeeRate} sat/vbyte</b> {mintFeeRate === RECOMMENDED_FEE_RATE ? '(recommended)' : ''}
          </p>
          <Form.Range min={MIN_FEE_RATE} max="80" defaultValue={mintFeeRate} onChange={(evt) => setMintFeeRate(evt.target.value)} />
          <p>
            <b>Total Price:</b> {totalPriceBtcDisplay()}
          </p>
          <p>
            (Minting {numToMint} {config.name}{numToMint === 1 ? '' : 's'})
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => {
            setShowSelectFeeRateModal(false)
            setShowBeginMintModal(true)
          }}>
            Back
          </Button>
          <Button variant="primary" onClick={() => {
            setShowSelectFeeRateModal(false);
            setShowConfirmMintModal(true);
          }}>
            Next
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={showPromptForAddressModal} onHide={() => setShowPromptForAddressModal(false)} className="py-5">
        <Modal.Header closeButton className="p-4">
          <Modal.Title>Enter your address</Modal.Title>
        </Modal.Header>
        <Modal.Body className="modal-body p-4">
          <div>
            Where would you like to receive your inscription?
          </div>
          <Container className="d-flex flex-column pt-2">
            <div>
              <Button variant="primary" className="my-2 w-50" onClick={async () => {
                if (!window.unisat) {
                  alert("Oops! It looks like you haven't set up Unisat")
                  return
                }
                console.log(`Requesting unisat`)
                const address = (await window.unisat.requestAccounts())[0]
                console.log(address)
                setDestinationBtcAddress(address)
                setCustomAddressUsed(false)
                setShowPromptForAddressModal(false)
                // setShowConfirmMintModal(true)
                await fetchAndHandleAllowlistDetails(address)
              }}>Unisat</Button>
              <div>
              <Button variant="primary" className="my-2 w-50" onClick={async () => {
                const getAddressOptions = {
                  payload: {
                    purposes: ['ordinals'],
                    message: 'Address for receiving Ordinals and payments',
                    network: {
                      type: TESTNET ? 'Testnet' : 'Mainnet'
                    },
                  },
                  onFinish: async (response) => {
                    console.log(response)
                    const address = response.addresses[0].address
                    console.log(address)
                    setDestinationBtcAddress(address)
                    setCustomAddressUsed(false)
                    setShowPromptForAddressModal(false)
                    // setShowConfirmMintModal(true)
                    await fetchAndHandleAllowlistDetails(address)
                  },
                  onCancel: () => alert('Request canceled'),
                }
                await getAddress(getAddressOptions).catch(err => {
                    console.log(err)
                    alert('No Xverse wallet detected')
                })
              }}>Xverse</Button>
              </div>
              <div>
                <Button variant="primary" className="my-2 w-50" onClick={async () => {
                  if (!window.nostr) {
                    alert("Oops! It looks like you haven't set up your Nostr key yet. Make sure you have Alby browser extension installed AND have gone into the Alby settings and created a Nostr key. See the Discord's #set-up-wallet channel for screenshots.")
                    return
                  }
                  const npub = await window.nostr.getPublicKey().catch(err => {
                    alert('Error getting public key from Nostr Wallet. It is recommended to use Alby wallet (getalby.com)')
                    return null
                  })
                  if (!npub) return
                  setNostrPublicKey(npub)
                  const pubkeyBuffer = Buffer.from(npub, 'hex')
                  const address = bitcoin.payments.p2tr({ pubkey: pubkeyBuffer, network: TESTNET ? bitcoin.networks.testnet : bitcoin.networks.bitcoin }).address;
                  setDestinationBtcAddress(address)
                  setCustomAddressUsed(false)
                  setShowPromptForAddressModal(false)
                  // setShowConfirmMintModal(true)
                  await fetchAndHandleAllowlistDetails(address)
                }}>Alby / Nosft</Button>
              </div>
              <div>
                <Button variant="primary" className="my-2 w-50" onClick={() => {
                  console.log(window.webbtc)
                  setShowPromptForAddressModal(false)
                  setShowCustomAddressModal(true)
                }}>Custom Address</Button>
              </div>
            </div>
          </Container>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => {
            setShowPromptForAddressModal(false)
            // setShowBeginMintModal(true)
          }}>
            Back
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={showCustomAddressModal} onHide={() => setShowCustomAddressModal(false)} className="modal py-5">
        <Modal.Header closeButton className="modal-header p-4" >
          <Modal.Title>Enter bitcoin address</Modal.Title>
        </Modal.Header>
        <Modal.Body className="modal-body p-4">
          <div>This should be a wallet with coin control dedicated to your ordinals. </div><br />
          <InputGroup className="mb-3">
            <Form.Control onChange={handleBtcAddressChange}
              placeholder="Paste BTC address here"
              aria-label="Paste BTC address heres"
              aria-describedby="basic-addon2"
              isInvalid={!isBtcInputAddressValid}
              autoFocus
            />
            <Form.Control.Feedback type="invalid">
              <br />That is not a valid {TESTNET ? 'testnet' : 'mainnet'} BTC address
            </Form.Control.Feedback>
          </InputGroup>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => {
            setShowCustomAddressModal(false)
            setShowPromptForAddressModal(true)
          }}>
            Back
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={showConfirmMintModal} onHide={() => setShowConfirmMintModal(false)} className="modal py-5">
        <Modal.Header closeButton className="modal-header p-4" >
          <Modal.Title>Confirm mint?</Modal.Title>
        </Modal.Header>
        <Modal.Body className="modal-body p-4">
          <div>
            <b>Number to mint:</b> {numToMint}
            <br /><br />
            <b>Fee rate:</b> {mintFeeRate} sat/vbyte
            <br /><br />
            <b>Total price:</b> {totalPriceBtcDisplay()}
            <br /><br />
            <b>Receive to:</b> {destinationBtcAddress}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => {
            setShowConfirmMintModal(false)
            setShowSelectFeeRateModal(true)
          }}>
            Back
          </Button>
          <Button variant="primary" onClick={async () => {
            setShowConfirmMintModal(false);
            setShowAwaitingInvoiceModal(true);
            let response
            try {
              response = await axios.post(`${API_BASE_URL}/v1/inscriptions/collections/mint`,
                {
                  collection_id: COLLECTION_ID,
                  num_to_mint: numToMint,
                  receive_address: destinationBtcAddress,
                  fee_rate: parseFloat(mintFeeRate),
                }
              )
            } catch (err) {
              console.log(err)
              console.log(err.message)
              setShowAwaitingInvoiceModal(false)
              setShowErrorModal(true)
              return
            }
            console.log(response)
            const {
              bolt11_invoice,
              payment_address,
              mint_attempt_id
            } = response.data
            setInvoiceToPay(bolt11_invoice)
            setPaymentBtcAddress(payment_address)
            setInvoiceDetails({
              // description: parsedInvoice.description,
              expiresAt: Date.now() + INVOICE_EXPIRY_MS//new Date(parsedInvoice.expires_at)
            })
            setMintAttemptId(mint_attempt_id)
            setShowAwaitingInvoiceModal(false)
            if (payment_address) {
              setShowChoosePaymentModal(true)
            } else {
              setShowPayLightningModal(true)
            }
          }}>
            Next
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={showAwaitingInvoiceModal} onHide={() => setShowAwaitingInvoiceModal(false)} className="py-5">
        <Modal.Header closeButton className="p-4">
          <Modal.Title>Getting the {config.name}{numToMint === 1 ? '' : 's'} ready...</Modal.Title>
        </Modal.Header>
        <Modal.Body className="px-5 py-3 center-contents">
          <br /><br />
          <TailSpin stroke="#000000" speed={.75} />
          <br /><br /><br />
        </Modal.Body>
      </Modal>
      <Modal show={showChoosePaymentModal} onHide={() => setShowChoosePaymentModal(false)} className="py-5">
        <Modal.Header closeButton className="p-4">
          <Modal.Title>Choose payment method</Modal.Title>
        </Modal.Header>
        <Modal.Body className="px-5 py-3">
          <Button variant="primary" className="my-2 w-50" onClick={async () => {
            setShowChoosePaymentModal(false)
            setShowPayOnchainModal(true)
            if (!window.unisat) {
              alert("Oops! It looks like you haven't set up Unisat")
              return
            }
            await unisat.sendBitcoin(paymentBtcAddress, totalPriceSats(), { feeRate: parseInt(mintFeeRate)})
          }}>Unisat</Button>
          <br/><br/>
          <Button variant="primary" className="my-2 w-50" onClick={() => {
            setShowChoosePaymentModal(false)
            setShowPayOnchainModal(true)
          }}>Xverse</Button>
          <br/><br/>
          <Button variant="primary" className="my-2 w-50" onClick={() => {
            setShowChoosePaymentModal(false)
            setShowPayOnchainModal(true)
          }}>Other On-Chain</Button>
          <br/><br/>
          <Button variant="primary" className="my-2 w-50" onClick={() => {
            setShowChoosePaymentModal(false)
            setShowPayLightningModal(true)
          }}>Lightning</Button>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => {
            setShowChoosePaymentModal(false)
            setShowConfirmMintModal(true)
          }}>
            Back
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={showPayLightningModal} onHide={() => setShowPayLightningModal(false)} className="py-5">
        <Modal.Header closeButton className="p-4">
          <Modal.Title>Pay with Lightning</Modal.Title>
        </Modal.Header>
        <Modal.Body className="px-5 py-3 center-contents modal-body">
          Minting {numToMint} {config.name}{numToMint === 1 ? '' : 's'} at {mintFeeRate} sat/vbyte for {totalPriceBtcDisplay()}
          <br /><br />
          <Button variant="primary" onClick={async () => {
            if (!window.webln) {
              alert("Looks like you don't have a browser wallet installed... Set up Alby (getalby.com) to pay an invoice with one click!")
              return
            }
            await window.webln.enable()
            const resp = await window.webln.sendPayment(invoiceToPay).catch(err => {
              console.log(err)
              return null
            })
            if (resp && resp.preimage) {
              console.log(resp)
              setShowPayLightningModal(false)
              setShowFinalInfoModal(true)
            }
          }}> Pay ⚡ Now </Button>
          <br /><br />
          <Button className="mx-1 my-1" variant="outline-primary" onClick={() => {
            setShowQrCode(!showQrCode)
          }}> {showQrCode ? 'Hide' : 'Show'} QR Code </Button>
          <Button className="mx-1 my-1" onClick={() => {
            navigator.clipboard.writeText(invoiceToPay)
          }} variant="outline-primary"><FaRegCopy /> Copy Invoice</Button>
          <br /><br />
          {showQrCode ?
            <>
              <QRCodeSVG size="240" value={`lightning:${invoiceToPay}`} /><br /><br />
            </>
            :
            <></>
          }
          <Countdown date={new Date(invoiceDetails.expiresAt)} renderer={countdownRenderer} />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => {
            setShowPayLightningModal(false)
            if (paymentBtcAddress) {
              setShowChoosePaymentModal(true)
            } else {
              setShowConfirmMintModal(true)
            }
          }}>
            Back
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={showPayOnchainModal} onHide={() => setShowPayOnchainModal(false)} className="py-5">
        <Modal.Header closeButton className="p-4">
          <Modal.Title>Pay with BTC on-chain</Modal.Title>
        </Modal.Header>
        <Modal.Body className="px-5 py-3 center-contents modal-body">
          Send exactly {totalPriceBtcDisplay()} to the following address:
          <br /><br />
          {paymentBtcAddress}
          <br /><br />
          <Button className="mx-1 my-1" onClick={() => {
            navigator.clipboard.writeText(paymentBtcAddress)
          }} variant="outline-primary"><FaRegCopy /> Copy Address</Button>
          <br /><br />
          <Countdown date={new Date(invoiceDetails.expiresAt)} renderer={countdownRenderer} />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => {
            setShowPayOnchainModal(false)
            setShowChoosePaymentModal(true)
          }}>
            Back
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={showAwaitingMintModal} onHide={() => setShowAwaitingMintModal(false)} className="py-5">
        <Modal.Header closeButton className="p-4">
          <Modal.Title>Minting your {config.name}{numToMint === 1 ? '' : 's'}..</Modal.Title>
        </Modal.Header>
        <Modal.Body className="px-5 py-3 center-contents">
          <br /><br />
          <TailSpin stroke="#000000" speed={.75} />
          <br /><br /><br />
        </Modal.Body>
      </Modal>
      <Modal show={showLoadingModal} onHide={() => setShowLoadingModal(false)} className="py-5">
        <Modal.Body className="px-5 py-3 center-contents">
          <br /><br />
          <TailSpin stroke="#000000" speed={.75} />
          <br /><br /><br />
        </Modal.Body>
      </Modal>
      <Modal show={showPendingOnchainModal} onHide={() => setShowPendingOnchainModal(false)} className="py-5">
        <Modal.Header closeButton className="p-4">
          <Modal.Title>Payment Detected</Modal.Title>
        </Modal.Header>
        <Modal.Body className="px-5 py-3 center-contents">
          Your payment has been detected and is awaiting confirmation. This may take a long time.
          <br /><br />
          <b>Leave this window open to track the status of your mint.</b>
          <br /><br />
          <TailSpin stroke="#000000" speed={.75} />
          <br /><br />
        </Modal.Body>
      </Modal>
      <Modal show={showFinalInfoModal} onHide={() => setShowFinalInfoModal(false)} className="py-5">
        <Modal.Header closeButton className="p-4">
          <Modal.Title>Success</Modal.Title>
        </Modal.Header>
        <Modal.Body className="px-5 py-3 center-contents">
          Payment confirmed!
          <br />< br/>
          {
            minted ?
              <>
              </>
              :
              <>
                Your mint is processing now, keep this window open...
                <br /><br />
                <TailSpin stroke="#000000" speed={.75} />
              </>
          }
          {mintOutpoints.map(it => {
            return (
              <>
                {
                  it === null ?
                    <>
                      <br />Mint pending...<br /><br />
                      <TailSpin stroke="#000000" speed={.75} />
                    </>
                    :
                    <a
                      href={`${MEMPOOL_URL}/tx/${it.split(':')[0]}`}
                      target="_blank"
                    >
                      {it}
                    </a>

                }
                <br /><br />
              </>
            )
          })}
        </Modal.Body>
      </Modal>
      <Modal show={showErrorModal} onHide={() => setShowErrorModal(false)} className="py-5">
        <Modal.Header closeButton className="p-4">
          <Modal.Title>Error</Modal.Title>
        </Modal.Header>
        <Modal.Body className="px-5 py-3 center-contents">
          Oops sorry something went wrong
        </Modal.Body>
      </Modal>
      <Modal show={showNoneAvailableModal} onHide={() => setShowNoneAvailableModal(false)} className="py-5">
        <Modal.Header closeButton className="p-4">
          <Modal.Title>No {config.title} available</Modal.Title>
        </Modal.Header>
        <Modal.Body className="px-5 py-3">
          Satoshi Cards are for sale on <a href={MARKETPLACE_URL} target= "_blank">Magic Eden</a>
          <br /><br />
          {liveCollectionInfo()}
        </Modal.Body>
      </Modal>
      <Modal show={showNotAllowedModal} onHide={() => setShowNotAllowedModal(false)} className="py-5">
        <Modal.Header closeButton className="p-4">
          <Modal.Title>Address not allowed</Modal.Title>
        </Modal.Header>
        <Modal.Body className="px-5 py-3">
          <p>
            Sorry the address <b>{destinationBtcAddress}</b> is not allowed to mint {config.name}s at this time.
          </p>
          <p>
            Look for {config.title} on the secondary markets once the mint is complete!
          </p>
        </Modal.Body>
      </Modal>
      <Modal show={showViewInfoModal} onHide={() => setShowViewInfoModal(false)} className="py-5">
        <Modal.Header closeButton className="p-4">
          <Modal.Title>{config.title} Collection Info</Modal.Title>
        </Modal.Header>
        <Modal.Body className="px-5 py-3">
          {liveCollectionInfo()}
        </Modal.Body>
      </Modal>
    </>
  )
}

export default App;
